import React, { useEffect } from "react";
import { graphql } from "gatsby";
import tw from "twin.macro";

import { Layout, Grid, Logo } from "~components";
import { useApp } from "~hooks";

const Container = tw.section`relative w-full max-w-[1440px] h-screen mx-auto py-6 md-t:py-12 overflow-hidden`;

const NotFound = ({ data: { sanityHome } }) => {
  const { logo } = sanityHome;
  const { setImageLoaded, setLoading } = useApp();

  useEffect(() => {
    setImageLoaded(false);
    setLoading(false);
  }, []);

  return (
    <Layout>
      <Container>
        <Logo logo={logo} loading={false} imageLoaded />

        <Grid css={tw`absolute bottom-[3rem]`}>
          <h2 tw="col-span-full font-main italic text-mobile md-t:text-desktop">
            404 Page not found
          </h2>
        </Grid>
      </Container>
    </Layout>
  );
};

export default NotFound;

export const query = graphql`
  query NotFoundPage {
    sanityHome {
      logo {
        asset {
          gatsbyImageData(
            width: 720
            formats: [WEBP, PNG, JPG, AVIF]
            placeholder: BLURRED
          )
        }
        altText
      }
    }
  }
`;
